import {} from '@graphcommerce/graphcms-ui'
import { RowContentLinksFragment } from './RowContentLinks.gql'
import { Grid, ImageSlider, ImageTextSlider, Inline, CheckList } from './variant'

type VariantRenderer = Record<
  NonNullable<RowContentLinksFragment['rowContentLinkVariant']>,
  React.VFC<RowContentLinksFragment>
>

type RowContentLinksProps = RowContentLinksFragment & {
  renderer?: Partial<VariantRenderer>
}

const defaultRenderer: Partial<VariantRenderer> = {
  Grid,
  ImageSlider,
  ImageTextSlider,
  Inline,
  CheckList,
}

export function RowContentLinks(props: RowContentLinksProps) {
  const { renderer, rowContentLinkVariant, ...RowContentLinksProps } = props
  const mergedRenderer = { ...defaultRenderer, ...renderer } as VariantRenderer

  if (!rowContentLinkVariant) return null

  const RenderType =
    mergedRenderer?.[rowContentLinkVariant] ??
    (() => {
      if (process.env.NODE_ENV !== 'production')
        return <>renderer for {rowContentLinkVariant} not found</>
      return null
    })

  return <RenderType {...RowContentLinksProps} />
}
