import { RichText } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import {
  responsiveVal,
  SidebarSlider,
  extendableComponent,
  breakpointVal,
} from '@graphcommerce/next-ui'
import { Box, Container, Link, SxProps, Theme, Typography, useTheme } from '@mui/material'
import { RowSwipeablePageLinksFragment } from './RowSwipeablePageLinks.gql'

type RowSwipeablePageLinksProps = RowSwipeablePageLinksFragment & {
  sx?: SxProps<Theme>
}

const compName = 'RowSwipeablePageLinks' as const
const parts = ['root'] as const
const { classes } = extendableComponent(compName, parts)

export function RowSwipeablePageLinks(props: RowSwipeablePageLinksProps) {
  const { rowSwipableTitle, items, sx = [] } = props
  const theme = useTheme()

  if (!items || items.length === 0) return null

  return (
    <Container
      className={classes.root}
      maxWidth={false}
      sx={[
        {
          marginBottom: theme.spacings.md,
          '& > .MuiContainer-root': {
            mb: theme.spacings.md,
            ...breakpointVal(
              'borderRadius',
              theme.shape.borderRadius * 5,
              theme.shape.borderRadius * 7,
              theme.breakpoints.values,
            ),
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <SidebarSlider
        sx={{
          backgroundColor: 'secondary.light',
          gap: theme.spacings.sm,
          [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
          },

          '&.MuiContainer-root': {
            padding: {
              xs: theme.spacings.lg,
              md: `${theme.spacings.md} 0 ${theme.spacings.md} ${theme.spacings.md}`,
            },
          },

          '& .SidebarSlider-grid': {
            gridTemplateColumns: {
              xs: 'none',
              md: 'minmax(150px, 27%) 1fr',
              lg: 'minmax(150px, 22%) 1fr',
            },
            columnGap: { xs: theme.spacings.xs },
          },

          '& .SidebarSlider-scroller': {
            gridAutoColumns: `100%`,
            gap: { xs: theme.spacings.lg, md: theme.spacings.xl, lg: theme.spacings.xxl },
            [theme.breakpoints.up('sm')]: {
              // Item width
              gridAutoColumns: `minmax(${responsiveVal(150, 600)}, 60vh)`,
            },
          },
          '& .SidebarSlider-sliderButtons': {
            '& .MuiFab-root': {
              background: theme.palette.primary.main,
              '&:hover': {
                background: theme.palette.primary.dark,
              },
              [theme.breakpoints.down('sm')]: {
                width: 36,
                height: 36,
              },
            },
            '& svg': {
              stroke: theme.palette.primary.contrastText,
              width: 30,
              height: 30,
              [theme.breakpoints.down('sm')]: {
                width: 20,
                height: 20,
              },
            },
          },
          '& .SidebarSlider-sidebar': {
            padding: 0,
            // todo: make the counter optional in GraphCommerce
            '& > div:last-of-type': {
              display: 'none',
            },
          },

          '& .SidebarSlider-centerLeft, & .SidebarSlider-centerRight': {
            top: `calc(50% - 70px)`,
            [theme.breakpoints.down('sm')]: {
              top: `calc(50% - 60px)`,
              '& button': {
                display: 'flex',
              },
            },
          },

          '& .MuiFab-root': {
            zIndex: 'inherit',
          },
        }}
        sidebar={
          <Box
            sx={{
              maxWidth: { xs: 'none', lg: '66%' },

              '& h1, & h2, & h3': {
                color: 'primary.main',
              },
            }}
          >
            {rowSwipableTitle && (
              <RichText
                raw={rowSwipableTitle.raw}
                sxRenderer={{
                  paragraph: {
                    ...theme.typography.h6,
                    a: {
                      color: theme.palette.text.primary,
                      textDecoration: 'underline',
                    },
                  },
                }}
              />
            )}
          </Box>
        }
      >
        {items?.map((pageLink) => (
          <div key={pageLink.id}>
            <Link href={pageLink.url} color='inherit' underline='none'>
              {pageLink?.asset && (
                <Box
                  sx={{
                    ...breakpointVal(
                      'borderRadius',
                      theme.shape.borderRadius * 4,
                      theme.shape.borderRadius * 6,
                      theme.breakpoints.values,
                    ),
                    overflow: 'hidden',
                    marginBottom: theme.spacings.sm,
                  }}
                >
                  <Image
                    layout='responsive'
                    width={600}
                    height={400}
                    src={pageLink.asset.url}
                    sx={{
                      display: 'block',
                    }}
                    sizes={{
                      0: '300px',
                      [theme.breakpoints.values.md]: '360px',
                      [theme.breakpoints.values.lg]: '600px',
                    }}
                    alt={pageLink.title || ''}
                  />
                </Box>
              )}
              {pageLink?.title && (
                <Typography
                  component='h3'
                  variant='h4'
                  sx={{
                    color: 'primary.main',
                  }}
                >
                  {pageLink.title}
                </Typography>
              )}
            </Link>
          </div>
        ))}
      </SidebarSlider>
    </Container>
  )
}
