import { useCustomerSession } from '@graphcommerce/magento-customer'
import { Money } from '@graphcommerce/magento-store'
import { extendableComponent } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Typography, TypographyProps, Box } from '@mui/material'
import { ProductPagePriceAddInclExclFragment } from '../InclExclPrices/queries/ProductPriceInclExcl.gql'

const { classes, selectors } = extendableComponent('ProductListPrice', [
  'root',
  'discountPrice',
] as const)

type ProductListPriceProps = ProductPagePriceAddInclExclFragment['price_range']['minimum_price'] &
  Pick<TypographyProps, 'sx'>

export function ProductListPrice(props: ProductListPriceProps) {
  const { regular_price_excl, final_price_excl, final_price_incl, sx = [] } = props

  const { loggedIn } = useCustomerSession()

  return (
    <Box
      sx={[
        {
          '& .ProductListPrice-root': {
            position: 'relative',
          },
          '& .MuiTypography-caption': {
            position: 'relative',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography component='div' variant='subtitle2' className={classes.root} sx={sx}>
        {regular_price_excl?.value !== final_price_excl?.value && (
          <Box
            sx={{
              display: 'inline-block',
              color: 'text.disabled',
              position: 'relative',
              clear: 'both',
              '&::after': {
                content: '""',
                height: '1px',
                width: '100%',
                position: 'absolute',
                inset: 0,
                margin: 'auto',
                backgroundColor: 'text.disabled',
              },
            }}
            className={classes.discountPrice}
          >
            <Money
              {...regular_price_excl}
              formatOptions={{ style: 'decimal', maximumFractionDigits: 2 }}
            />{' '}
            <Typography variant='caption' component='span' sx={{ whiteSpace: 'nowrap' }}>
              <Trans id='excl. vat' />
            </Typography>
          </Box>
        )}
        <Box className='excl-vat'>
          <Money
            {...final_price_excl}
            formatOptions={{ style: 'decimal', maximumFractionDigits: 2 }}
          />{' '}
          <Typography variant='caption' component='span' sx={{ whiteSpace: 'nowrap' }}>
            <Trans id='excl. vat' />
          </Typography>
        </Box>
      </Typography>

      {!loggedIn && (
        <Typography component='div' variant='subtitle2' className={classes.root} sx={sx}>
          <Box className='incl-vat'>
            <Money
              {...final_price_incl}
              formatOptions={{ style: 'decimal', maximumFractionDigits: 2 }}
            />{' '}
            <Typography variant='caption' component='span' sx={{ whiteSpace: 'nowrap' }}>
              <Trans id='incl. vat' />
            </Typography>
          </Box>
        </Typography>
      )}
    </Box>
  )
}

ProductListPrice.selectors = selectors
