import { Container } from '@mui/material'
import Script from 'next/script'
import { useCallback, useEffect } from 'react'
import { RowFormsAppFragment } from './RowFormsApp.gql'

export function RowFormsApp(props: RowFormsAppFragment) {
  const { formId } = props
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line new-cap
  const init = useCallback(() => new formsapp(formId), [formId])

  useEffect(() => {
    if (globalThis.formsapp && !document.querySelector('iframe[src*="forms.app"]')) {
      init()
    }
  })

  return (
    <Container>
      <div {...({ formsappid: formId } as any)} />
      <Script
        src='https://my.forms.app/static/iframe.js'
        strategy='afterInteractive'
        onLoad={init}
      />
    </Container>
  )
}
