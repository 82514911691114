import { Scroller, ScrollerButton, ScrollerProvider } from '@graphcommerce/framer-scroller'
import {
  extendableComponent,
  iconChevronLeft,
  iconChevronRight,
  IconSvg,
  Row,
  useFabSize,
} from '@graphcommerce/next-ui'
import { Box, ContainerProps, SxProps, Theme, Typography } from '@mui/material'
import React from 'react'

export type ContentLinksProps = {
  title: string
  children: React.ReactNode
  sx?: SxProps<Theme>
} & Pick<ContainerProps, 'maxWidth'>

const compName = 'ContentLinks' as const
const parts = ['root', 'scroller', 'title', 'sliderButtons', 'centerRight', 'centerLeft'] as const
const { classes } = extendableComponent(compName, parts)

export function ContentLinks(props: ContentLinksProps) {
  const { title, children, sx = [], maxWidth } = props

  const fabSize = useFabSize('responsive')

  return (
    <Row
      className={classes.root}
      sx={[{ position: 'relative' }, ...(Array.isArray(sx) ? sx : [sx])]}
      maxWidth={maxWidth}
    >
      <ScrollerProvider scrollSnapAlign='end'>
        <Scroller
          className={classes.scroller}
          hideScrollbar
          sx={(theme) => ({
            justifyContent: 'start',
            gap: `${theme.spacings.xxs}`,
            gridAutoColumns: `max-content`,

            '&.smGridDirInline > *:not(:nth-last-of-type(-n+2))': {
              scrollSnapAlign: {
                xs: 'center',
                md: 'end',
              },
            },

            '&.smGridDirInline > *:nth-last-of-type(-n+2)': {
              scrollSnapAlign: {
                xs: 'start',
                md: 'end',
              },
            },
          })}
        >
          <Typography variant='subtitle1' component='h2' className={classes.title}>
            {title}
          </Typography>
          {children}
        </Scroller>
        <Box
          className={classes.centerLeft}
          sx={(theme) => ({
            display: 'grid',
            gridAutoFlow: 'row',
            gap: theme.spacings.xxs,
            position: 'absolute',
            left: theme.spacings.sm,
            top: `calc(50% - calc(${fabSize} / 2))`,
          })}
        >
          <ScrollerButton
            direction='left'
            className={classes.sliderButtons}
            sx={{ display: 'flex' }}
            size='responsive'
          >
            <IconSvg src={iconChevronLeft} />
          </ScrollerButton>
        </Box>
        <Box
          className={classes.centerRight}
          sx={(theme) => ({
            display: 'grid',
            gap: theme.spacings.xxs,
            position: 'absolute',
            right: theme.spacings.sm,
            top: `calc(50% - calc(${fabSize} / 2))`,
          })}
        >
          <ScrollerButton
            direction='right'
            className={classes.sliderButtons}
            sx={{ display: 'flex' }}
            size='responsive'
          >
            <IconSvg src={iconChevronRight} />
          </ScrollerButton>
        </Box>
      </ScrollerProvider>
    </Row>
  )
}
