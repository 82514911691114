import { Box, Button } from '@mui/material'
import { ProductLabelFragment } from './ProductLabel.gql'

export function ProductLabel(props: ProductLabelFragment) {
  const { product_label } = props

  if (!product_label) return null

  return (
    <Box sx={{ mb: 1 }}>
      <Button
        size='small'
        variant='contained'
        color='primary'
        sx={(theme) => ({
          '&.MuiButton-root': {
            whiteSpace: 'nowrap',
            fontWeight: 500,
            fontVariationSettings: "'wght' 500",
            fontSize: { sm: '13px' },
            padding: '2px 8px',
          },
          '&:hover, &:focus, &:active': { backgroundColor: theme.palette.primary.main },
          [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.caption,
          },
        })}
      >
        {product_label}
      </Button>
    </Box>
  )
}
