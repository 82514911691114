import { RichText } from '@graphcommerce/graphcms-ui'
import { Quote } from '@graphcommerce/next-ui'
import { Box, SxProps, Theme } from '@mui/material'
import { RowQuoteFragment } from './RowQuote.gql'

type RowQuoteProps = RowQuoteFragment & {
  sx?: SxProps<Theme>
}

export function RowQuote(props: RowQuoteProps) {
  const { quote, sx = [] } = props

  return (
    <Quote>
      <Box
        sx={[
          {
            textAlign: 'center',
            maxWidth: { xs: '80%', md: '40%' },
            margin: 'auto',
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <RichText
          {...quote}
          sxRenderer={{
            paragraph: (theme) => ({
              a: {
                color: theme.palette.text.primary,
                textDecoration: 'underline',
              },
            }),
          }}
        />
      </Box>
    </Quote>
  )
}
