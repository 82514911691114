import { mergeDeep } from '@graphcommerce/graphql'
import {
  AddToCartItemSelector,
  productLink,
  type ProductPageMetaProps,
} from '@graphcommerce/magento-product'
import { useConfigurableSelectedVariant } from '@graphcommerce/magento-product-configurable'
import { PluginProps } from '@graphcommerce/next-config'
import { useStorefrontConfig } from '@graphcommerce/next-ui'

export const component = 'ProductPageMeta'
export const exported = '@graphcommerce/magento-product/components/ProductPageMeta/ProductPageMeta'

function ErpBasedCanonicalPlugin(props: PluginProps<ProductPageMetaProps> & AddToCartItemSelector) {
  const { Prev, product, index = 0, children, ...rest } = props

  const variantForSimpleEnabled = import.meta.graphCommerce.configurableVariantForSimple
  const variant = useConfigurableSelectedVariant({ url_key: product?.url_key, index })
  const variantProduct = variantForSimpleEnabled && variant ? mergeDeep(product, variant) : product

  const { sku, url_key, __typename, erp_main_shop } = variantProduct

  const erpMainShop = import.meta.graphCommerce.storefront.find(
    (store) => store.magentoStoreCode === erp_main_shop,
  )
  const currentDomain = useStorefrontConfig().domain
  const erpMainShopDomain = erpMainShop?.domain

  // remove when kommago.nl goes live:
  function removeNumberFromURL(url: string | null | undefined) {
    if (!url) return null
    const regex = /-\d+$/
    return url.replace(regex, '')
  }

  const kommagoUrlKey = removeNumberFromURL(url_key)

  let canonical: string

  if (currentDomain === erpMainShopDomain) {
    canonical = productLink({ url_key, __typename })
  } else if (erpMainShopDomain?.includes('kommago.nl')) {
    canonical = `https://www.kommago.nl/${kommagoUrlKey}/pid=${sku}`
  } else {
    canonical = `https://${erpMainShopDomain}/p/${url_key}`
  }
  // remove when kommago.nl goes live until here

  const forwardProps = {
    ...rest,
    canonical,
  }

  return <Prev product={product} {...forwardProps} />
}

export const Plugin = ErpBasedCanonicalPlugin
