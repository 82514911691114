import type { Resolvers, Money } from '@graphcommerce/graphql-mesh'

const magentoIncludesTax = true
const vatPercentage = 1.21

export function inclTax(basePrice: Money): Money {
  if (magentoIncludesTax) return basePrice
  return { ...basePrice, value: (basePrice.value ?? 0) * vatPercentage }
}

export function exclTax(basePrice: Money): Money {
  if (!magentoIncludesTax) return basePrice
  return { ...basePrice, value: (basePrice.value ?? 0) / vatPercentage }
}

export const resolver: Resolvers = {
  ProductPrice: {
    final_price_incl: (root) => inclTax(root.final_price),
    final_price_excl: (root) => exclTax(root.final_price),
    regular_price_incl: (root) => inclTax(root.regular_price),
    regular_price_excl: (root) => exclTax(root.regular_price),
  },
}
export default resolver
