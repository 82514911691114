import { Image } from '@graphcommerce/image'
import { Button } from '@mui/material'
import trustedshops from './trustedshops.svg'

export function ReviewTrustedShopBadge() {
  const trustedShopsUrl = `${import.meta.graphCommerce.trustedShopsUrl}`

  return (
    <Button
      target='_blank'
      href={trustedShopsUrl}
      variant='inline'
      sx={{ '&:hover': { background: 'none' } }}
    >
      <Image src={trustedshops} layout='fixed' unoptimized />
    </Button>
  )
}
