import { Box, Container, Divider, Typography } from '@mui/material'

import React from 'react'
import { RowCaseReferenceFragment } from './RowCaseReference.gql'

export function RowCaseReference(props: RowCaseReferenceFragment) {
  const { caseReferenceItems } = props

  return (
    <Container maxWidth='md' sx={(theme) => ({ mt: theme.spacings.xl, mb: theme.spacings.xl })}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '4fr 9fr',
          rowGap: 2,
        }}
      >
        {caseReferenceItems?.length > 0 &&
          caseReferenceItems.map((item) => (
            <React.Fragment key={item.id}>
              <Typography
                component='span'
                variant='overline'
                sx={(theme) => ({
                  py: 2,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  color: theme.palette.text.secondary,
                })}
              >
                {item.title}
              </Typography>
              <Typography
                component='span'
                variant='subtitle1'
                sx={(theme) => ({
                  py: 2,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                })}
              >
                {item.description}
              </Typography>
            </React.Fragment>
          ))}
      </Box>
    </Container>
  )
}
