import { Row } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Box, Typography, Container, SxProps, Theme } from '@mui/material'
import React from 'react'

type TableProps = {
  items: { label: string; value: React.ReactNode | string | null | undefined }[]
} & {
  sx?: SxProps<Theme>
}

export function Table(props: TableProps) {
  const { sx = [], items } = props

  return (
    <Row
      sx={(theme) => ({
        typography: 'subtitle1',
        color: 'text.secondary',
        margin: `${theme.spacings.lg} 0`,
        sx,
      })}
    >
      <Container
        sx={(theme) => ({
          mt: theme.spacings.xl,
          mb: theme.spacings.xl,
          display: 'flex',
          justifyContent: 'center',
        })}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '6fr 6fr',
            rowGap: 0,
            maxWidth: 960,
            width: '100%',
          }}
        >
          {items?.length > 0 &&
            items.map((item) => (
              <React.Fragment key={item.label}>
                <Typography
                  component='span'
                  variant='overline'
                  sx={(theme) => ({
                    py: 2,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    color: theme.palette.text.secondary,
                    display: 'flex',
                    alignItems: 'center',
                  })}
                >
                  <Trans id={item.label} />
                </Typography>
                <Typography
                  component='span'
                  variant='subtitle1'
                  sx={(theme) => ({
                    py: 4,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    color: theme.palette.text.primary,
                  })}
                >
                  {item.value}
                </Typography>
              </React.Fragment>
            ))}
        </Box>
      </Container>
    </Row>
  )
}
