import { ProductListItemsBase, ProductItemsGridProps } from '@graphcommerce/magento-product'
import { productListRenderer } from './productListRenderer'

export type ProductListItemsProps = Omit<ProductItemsGridProps, 'renderers'>

export function ProductListItems(props: ProductListItemsProps) {
  const { sx = [], size = 'normal' } = props
  return (
    <ProductListItemsBase
      renderers={productListRenderer}
      {...props}
      sx={[
        size === 'normal' && {
          gridTemplateColumns: {
            xs: `repeat(2, 1fr)`,
            sm: `repeat(3, 1fr)`,
            md: `repeat(4, 1fr)`,
            lg: `repeat(5, 1fr)`,
            xl: `repeat(5, 1fr)`,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  )
}
