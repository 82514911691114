import { ContentLinks } from '@graphcommerce/next-ui'
import { Link } from '@mui/material'

import { RowContentLinksFragment } from '../RowContentLinks.gql'

export function Inline(props: RowContentLinksFragment) {
  const { title, contentLinks } = props

  return (
    <ContentLinks
      title={title}
      sx={(theme) => ({
        mb: 0,
        pb: theme.spacings.sm,
        '& .ContentLinks-title': {
          fontWeight: 600,
        },
      })}
    >
      {contentLinks.map((contentLink) => (
        <Link
          href={contentLink.url}
          key={contentLink.id}
          variant='body1'
          color='inherit'
          underline='hover'
        >
          {contentLink.title}
        </Link>
      ))}
    </ContentLinks>
  )
}
