import type { Product_DatalayerItemFragment } from '@graphcommerce/google-datalayer/mapping/productToDatalayerItem/Product_DatalayerItem.gql'
import type { productToDatalayerItem as productToDatalayerItemType } from '@graphcommerce/google-datalayer/mapping/productToDatalayerItem/productToDatalayerItem'
import type { MethodPlugin } from '@graphcommerce/next-config'
import { storefrontConfig } from '@graphcommerce/next-ui'
import Router from 'next/router'

export const func = 'productToDatalayerItem'
export const exported =
  '@graphcommerce/google-datalayer/mapping/productToDatalayerItem/productToDatalayerItem'

export function getManufacturer(item: Partial<Product_DatalayerItemFragment>) {
  return (
    item.custom_attributes?.find(
      (attribute) => attribute?.attribute_metadata?.code === 'manufacturer',
    )?.selected_attribute_options?.attribute_option?.[0]?.label ?? undefined
  )
}

export function getDeliveryTimeOptionValue(item: Product_DatalayerItemFragment) {
  const deliveryTimeUid = item.custom_attributes?.find(
    (attribute) => attribute?.attribute_metadata?.code === 'delivery_time',
  )?.selected_attribute_options?.attribute_option?.[0]?.uid

  if (!deliveryTimeUid) return ''

  const decodedDeliveryTimeUid = atob(deliveryTimeUid)
  return decodedDeliveryTimeUid?.split('/').pop() || ''
}

const productToDatalayerItem: MethodPlugin<typeof productToDatalayerItemType> = (prev, item) => {
  const res = prev(item)

  // @ts-expect-error we're accessing a private field here. We expect this to be improved in GC in the future.
  const locale = Router.router?.state?.locale as string | undefined

  return {
    ...res,
    price: item.price_range.minimum_price.final_price_excl?.value ?? undefined,
    affiliation: storefrontConfig(locale)?.hygraphScopes?.[0],
    item_brand: getManufacturer(item),
    delivery_time: getDeliveryTimeOptionValue(item),
  }
}

export const plugin = productToDatalayerItem
