import { Asset } from '@graphcommerce/graphcms-ui'
import { breakpointVal } from '@graphcommerce/next-ui'
import { Box, Container, Link, useTheme } from '@mui/material'
import { ContentLinks } from '../RowContentLinks/ContentLinks'
import { RowCardFragment } from './RowCard.gql'

type RowCardProps = RowCardFragment

export function RowCard(props: RowCardProps) {
  const { cards } = props

  const theme = useTheme()

  if (!cards.length) return null

  return (
    <Container
      maxWidth='lg'
      sx={{
        // Adjust the margin size by subtracting the added padding on .Scroller-root.
        marginBottom: theme.spacings.md,
      }}
    >
      <Box>
        <ContentLinks
          title=''
          sx={{
            marginBottom: 0,
            '& h2': {
              display: 'none',
            },
            '& > .Scroller-root': {
              columnGap: theme.spacings.md,
              gridAutoColumns: { lg: 'initial' },
              gridTemplateColumns: {
                xs: 'repeat(3, minmax(250px, 1fr))',
                sm: 'repeat(3, minmax(300px, 1fr))',
                md: 'repeat(3, 1fr)',
              },
              // Adding padding to reveal the drop-shadow effect, as the slider is set to overflow: hidden
              paddingBottom: theme.spacings.xxs,
            },
            '& .MuiFab-root': {
              display: 'flex',
            },

            [theme.breakpoints.down('md')]: {
              marginBottom: theme.spacings.md,
            },
          }}
        >
          {cards.map((card) => {
            const pageLink = card.pageLinks[0]

            return (
              <Box
                key={card.id}
                sx={{
                  position: 'relative',
                  '&:hover .boxShadow,&:focus .boxShadow, &:active .boxShadow': {
                    boxShadow: theme.shadows[6],
                  },
                }}
              >
                <Box
                  className='boxShadow'
                  sx={{
                    position: 'absolute',
                    boxShadow: theme.shadows[3],
                    inset: 0,
                    transition: 'box-shadow  0.5s cubic-bezier(0.33, 1, 0.68, 1)',
                    ...breakpointVal(
                      'borderRadius',
                      theme.shape.borderRadius * 4,
                      theme.shape.borderRadius * 7,
                      theme.breakpoints.values,
                    ),
                  }}
                />
                <Box
                  key={pageLink.title}
                  sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    border: '1px solid rgba(0, 0, 0, 0.10)',
                    ...breakpointVal(
                      'borderRadius',
                      theme.shape.borderRadius * 4,
                      theme.shape.borderRadius * 7,
                      theme.breakpoints.values,
                    ),
                  }}
                >
                  <Link
                    href={pageLink.url}
                    variant={card.imageOverlay ? 'h3' : 'h5'}
                    color='inherit'
                    underline='none'
                    sx={{
                      textShadow: card.imageOverlay ? '0px 2px 5px rgba(0,0,0,0.3)' : 'none',

                      '& img': {
                        transition: 'transform 0.3s ease-in',
                        WebkitBackfaceVisibility: 'hidden',
                        transform: 'translateZ(0)',
                      },
                      '&:hover img, &:focus img, &:active img': {
                        transform: 'scale(1.05)',
                      },

                      [theme.breakpoints.down('md')]: {
                        ...theme.typography.h6,
                        fontSize: '16px',
                      },
                    }}
                  >
                    {pageLink?.asset && (
                      <Asset
                        asset={{ ...pageLink.asset, width: 456, height: 240 }}
                        sx={{
                          display: 'block',
                          position: 'absolute',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: '100%',
                          height: '100%',
                        }}
                        sizes={{
                          0: '248px',
                          [theme.breakpoints.values.md]: '298px',
                          [theme.breakpoints.values.lg]: '456px',
                        }}
                        loading='eager'
                      />
                    )}
                    <Box
                      sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: card.imageOverlay ? 'rgba(0,0,0,.2)' : 'transparent',
                        padding: card.imageOverlay ? theme.spacings.sm : 0,
                        color: card.imageOverlay ? 'primary.contrastText' : 'primary.main',
                        minHeight: { xs: '141px', sm: '180px', md: '240px' },
                      }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          marginTop: 'auto',
                          backgroundColor: card.imageOverlay
                            ? 'transparent'
                            : 'primary.contrastText',
                          padding: card.imageOverlay
                            ? 0
                            : `${theme.spacings.xs} ${theme.spacings.sm}`,
                          fontWeight: card.imageOverlay
                            ? theme.typography.h3.fontWeight
                            : theme.typography.h6.fontWeight,
                          fontVariationSettings: card.imageOverlay
                            ? theme.typography.h3.fontVariationSettings
                            : theme.typography.h6.fontVariationSettings,
                        }}
                      >
                        {card.title}
                      </Box>
                    </Box>
                  </Link>
                </Box>
              </Box>
            )
          })}
        </ContentLinks>
      </Box>
    </Container>
  )
}
