import {
  Scroller,
  ScrollerButton,
  ScrollerButtonProps,
  ScrollerProvider,
} from '@graphcommerce/framer-scroller'
import {
  IconSvg,
  extendableComponent,
  iconChevronLeft,
  iconChevronRight,
  responsiveVal,
} from '@graphcommerce/next-ui'
import { Box, Container, SxProps, Theme } from '@mui/material'
import { ReactNode } from 'react'

const { classes, selectors } = extendableComponent('FullWidthSlider', [
  'root',
  'grid',
  'title',
  'scrollerContainer',
  'scroller',
  'sliderButtons',
  'centerLeft',
  'centerRight',
] as const)

export type FullWidthSliderProps = {
  children: ReactNode
  title?: ReactNode
  sx?: SxProps<Theme>
  buttonSize?: ScrollerButtonProps['size']
}

export function FullWidthSlider(props: FullWidthSliderProps) {
  const { children, title, sx = [], buttonSize } = props

  return (
    <Container maxWidth={false} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <ScrollerProvider scrollSnapAlign='start'>
        <Box
          className={classes.title}
          sx={(theme) => ({
            marginBottom: theme.spacings.sm,
          })}
        >
          {title}
        </Box>

        <Box className={classes.scrollerContainer} sx={{ position: 'relative', minWidth: 1 }}>
          <Scroller
            className={classes.scroller}
            hideScrollbar
            sx={(theme) => ({
              gridColumnGap: theme.spacings.md,
              gridRowGap: theme.spacings.lg,
              paddingRight: theme.page.horizontal,
              gridAutoColumns: responsiveVal(200, 400),
            })}
          >
            {children}
          </Scroller>
          <Box
            className={classes.centerLeft}
            sx={(theme) => ({
              display: 'grid',
              gridAutoFlow: 'row',
              gap: theme.spacings.xxs,
              position: 'absolute',
              left: theme.spacings.sm,
              top: `calc(50% - 28px)`,
            })}
          >
            <ScrollerButton
              direction='left'
              className={classes.sliderButtons}
              sx={{ display: { xs: 'none', md: 'flex' } }}
              size={buttonSize}
            >
              <IconSvg src={iconChevronLeft} />
            </ScrollerButton>
          </Box>
          <Box
            className={classes.centerRight}
            sx={(theme) => ({
              display: 'grid',
              gap: theme.spacings.xxs,
              position: 'absolute',
              right: theme.spacings.sm,
              top: `calc(50% - 28px)`,
            })}
          >
            <ScrollerButton
              direction='right'
              className={classes.sliderButtons}
              sx={{ display: { xs: 'none', md: 'flex' } }}
              size={buttonSize}
            >
              <IconSvg src={iconChevronRight} />
            </ScrollerButton>
          </Box>
        </Box>
      </ScrollerProvider>
    </Container>
  )
}

FullWidthSlider.selectors = selectors
