import { RichText } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import { breakpointVal } from '@graphcommerce/next-ui'
import { Button, Theme } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { RowLinksFragment } from '../RowLinks.gql'

type BadgeProps = RowLinksFragment['pageLinks'][0] & {
  theme: Theme
}

export function Badge(props: BadgeProps) {
  const { theme, title, description, asset, url } = props

  return (
    <Button
      href={url}
      sx={{
        padding: 0,
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: '1fr 3fr',
        boxShadow: 6,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius * 0.75,
        minWidth: 280,
        flexGrow: 1,
        width: `calc(33.33% - ${theme.spacings.sm})`,
        overflow: 'hidden',
        textAlign: 'left',
      }}
    >
      <Box
        sx={{
          minWidth: 0,
          [theme.breakpoints.up('md')]: {
            aspectRatio: '1/1',
          },
        }}
      >
        <Image
          alt={asset?.alt ?? title}
          layout='fill'
          src={asset?.url ?? '/??'}
          unoptimized={asset?.mimeType === 'image/svg+xml' ?? true}
          sx={{ objectFit: 'cover', display: 'block' }}
        />
      </Box>

      <Box
        sx={{
          py: 0,
          px: theme.spacings.xs,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          component='p'
          variant='subtitle2'
          sx={{ fontWeight: '500', color: theme.palette.primary.main, mb: 0 }}
        >
          {title && title}
        </Typography>
        {description && (
          <RichText
            {...description}
            raw={{
              ...description.raw,
              children: [description.raw.children?.[0]],
            }}
            sxRenderer={{
              paragraph: {
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '2',
                ...breakpointVal('fontSize', 11, 16, theme.breakpoints.values),
              },
              link: {
                color: 'text.primary',
                textDecoration: 'underline',
              },
            }}
          />
        )}
      </Box>
    </Button>
  )
}
