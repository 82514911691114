import { Image } from '@graphcommerce/image'
import { breakpointVal, Row } from '@graphcommerce/next-ui'
import { Box, Container, Link, Typography, useTheme } from '@mui/material'
import { RowContentLinksFragment } from '../RowContentLinks.gql'

export function Grid(props: RowContentLinksFragment) {
  const { title, contentLinks } = props

  const theme = useTheme()

  return (
    <Row maxWidth={false}>
      <Box
        sx={{
          backgroundColor: 'background.image',
          padding: {
            xs: `${theme.spacings.lg} ${theme.spacings.md}`,
            lg: `${theme.spacings.xxl} ${theme.spacings.xxl}`,
          },
          ...breakpointVal(
            'borderRadius',
            theme.shape.borderRadius * 5,
            theme.shape.borderRadius * 7,
            theme.breakpoints.values,
          ),

          '& .MuiContainer-root': {
            paddingX: { md: theme.spacings.sm },
          },
        }}
      >
        <Container>
          <Typography variant='h2' sx={{ marginBottom: theme.spacings.md }}>
            {title}
          </Typography>

          <Box
            sx={{
              display: 'grid',
              gap: { xs: theme.spacings.lg },
              gridTemplateColumns: { xs: 'repeat(2, 1fr )', md: 'repeat(3, 1fr )' },
            }}
          >
            {contentLinks?.map((pageLink) => (
              <div key={pageLink.title}>
                <div>
                  <Link href={pageLink.url} color='inherit' underline='none'>
                    {pageLink?.asset && (
                      <Box
                        sx={{
                          ...breakpointVal(
                            'borderRadius',
                            theme.shape.borderRadius * 4,
                            theme.shape.borderRadius * 6,
                            theme.breakpoints.values,
                          ),
                          overflow: 'hidden',
                          marginBottom: theme.spacings.md,
                        }}
                      >
                        <Image
                          layout='responsive'
                          width={750}
                          height={500}
                          src={pageLink.asset.url}
                          sx={{
                            display: 'block',
                          }}
                          sizes={{
                            0: '80vw',
                            [theme.breakpoints.values.md]: '480px',
                            [theme.breakpoints.values.lg]: '500px',
                          }}
                          alt={pageLink.title || ''}
                        />
                      </Box>
                    )}
                    {pageLink?.title && (
                      <Typography component='h3' variant='h4'>
                        {pageLink.title}
                      </Typography>
                    )}
                  </Link>
                </div>
              </div>
            ))}
          </Box>
        </Container>
      </Box>
    </Row>
  )
}
