import { useScrollerContext, useScrollTo } from '@graphcommerce/framer-scroller'
import { useEffect } from 'react'

type AutoScrollProps = {
  timePerSlide?: number
  pause?: boolean
}

export function AutoScroll(props: AutoScrollProps) {
  const { getScrollSnapPositions, getSnapPosition, scroll } = useScrollerContext()
  const scrollTo = useScrollTo()
  const { pause = false, timePerSlide = 6000 } = props
  const { xProgress } = scroll

  useEffect(() => {
    const slide = async () => {
      if (pause) return
      const scrollPositions = getScrollSnapPositions().x
      const currentSlideIndex = Math.round(xProgress.get() * (scrollPositions.length - 1))

      if (currentSlideIndex === scrollPositions.length - 1) {
        await scrollTo({ x: scrollPositions[0], y: 0 }).catch(() => {})
      } else {
        await scrollTo(getSnapPosition('right')).catch(() => {})
      }
    }

    const id = setInterval(slide, timePerSlide)

    return () => clearInterval(id)
  }, [getScrollSnapPositions, getSnapPosition, pause, scrollTo, timePerSlide, xProgress])

  return null
}
