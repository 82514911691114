import { Asset, RichText } from '@graphcommerce/graphcms-ui'
import { Button } from '@graphcommerce/next-ui'
import { Box, useTheme } from '@mui/material'
import { BannerSlideFragment } from './BannerSlide.gql'

export function BannerSlide(props: BannerSlideFragment & { index: number }) {
  const { imageDesktop, imageMobile, copy, buttonText, buttonUrl, index } = props
  const theme = useTheme()

  const isDuplicatedAsset = imageMobile.url === imageDesktop.url

  const assetStyle = {
    display: 'block',
    objectFit: 'cover',
    position: 'absolute',
    width: '100%',
    height: '100%',
    inset: 0,
  }

  return (
    <Box sx={{ position: 'relative', height: 'auto', minHeight: { xs: '280px', md: '350px' } }}>
      {!isDuplicatedAsset && (
        <>
          <Asset
            asset={imageMobile}
            quality={84}
            loading={index === 0 ? 'eager' : 'lazy'}
            layout='fill'
            sizes={{ 0: '100vw', [theme.breakpoints.values.md]: '1px' }}
            sx={[assetStyle, { display: { xs: 'block', md: 'none' } }]}
          />
          <Asset
            asset={imageDesktop}
            quality={80}
            loading={index === 0 ? 'eager' : 'lazy'}
            layout='fill'
            sizes={{ 0: '1px', [theme.breakpoints.values.md]: '100vw' }}
            sx={[assetStyle, { display: { xs: 'none', md: 'block' } }]}
          />
        </>
      )}
      {isDuplicatedAsset && (
        <Asset
          asset={imageDesktop}
          quality={80}
          loading={index === 0 ? 'eager' : 'lazy'}
          layout='fill'
          sizes={{ 0: '100vw' }}
          sx={assetStyle}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          px: theme.spacings.md,
          pb: { xs: theme.spacings.lg, md: theme.spacings.md },
          position: 'relative',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        {copy && (
          <Box
            sx={{
              color: theme.palette.primary.contrastText,
              maxWidth: { xs: '100%', md: '40%' },
              mb: theme.spacings.xs,
            }}
          >
            <RichText
              {...copy}
              sxRenderer={{
                paragraph: {
                  typography: 'overline',
                },
                'heading-one': {
                  textTransform: 'uppercase',
                  lineHeight: 1.35,
                  mb: theme.spacings.xs,
                  '& strong': {
                    WebkitTextFillColor: 'transparent',
                    WebkitTextStroke: `1.2px #fff`,
                  },
                },
              }}
            />
          </Box>
        )}
        <Box>
          {buttonUrl && buttonUrl && (
            <Button href={buttonUrl} variant='pill' color='primary' size='large'>
              {buttonText}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}
