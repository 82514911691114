import { RichText } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import {
  IconBlock,
  IconBlocks,
  iconChat,
  iconEmail,
  iconPhone,
  IconSvg,
  useIconSvgSize,
} from '@graphcommerce/next-ui'
import iconWhatsapp from '../../Icons/whatsapp.svg'
import { RowServiceOptionsFragment } from './RowServiceOptions.gql'

type RowServiceOptionsProps = RowServiceOptionsFragment

export function RowServiceOptions(props: RowServiceOptionsProps) {
  const { title, linkList } = props
  const size = useIconSvgSize('large')
  return (
    <IconBlocks title={title}>
      {linkList?.links.map((serviceOption, i) => {
        const url =
          serviceOption.__typename === 'LinkExternal'
            ? serviceOption.url ?? ''
            : serviceOption.page?.url ?? ''

        return (
          <IconBlock
            key={serviceOption.title}
            href={url}
            title={serviceOption.title}
            icon={
              <>
                {i === 0 && <IconSvg src={iconEmail} size='large' />}
                {i === 1 && <IconSvg src={iconPhone} size='large' />}
                {i === 2 && <IconSvg src={iconChat} size='large' />}
                {i === 3 && (
                  <Image
                    src={iconWhatsapp}
                    layout='fixed'
                    unoptimized
                    sx={{ width: size, height: size }}
                  />
                )}
              </>
            }
            onClick={
              i === 2
                ? (e) => {
                    e.preventDefault()
                    globalThis.Trengo?.Api.Widget.open('chat')
                  }
                : undefined
            }
          >
            {serviceOption.description ? <RichText {...serviceOption.description} /> : undefined}
          </IconBlock>
        )
      })}
    </IconBlocks>
  )
}
