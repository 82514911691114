import { useQuery } from '@graphcommerce/graphql'
import { ProductReviewsProps } from '@graphcommerce/magento-review'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { Row } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Box, Button, Divider, Typography } from '@mui/material'
import { ProductReviews } from '../../../ProductPage/Reviews/ProductReviews'
import { RowProductFragment } from '../RowProduct.gql'

type ReviewsProps = RowProductFragment & Partial<ProductReviewsProps>

export function Reviews(props: ReviewsProps) {
  const { title, reviews, url_key, review_count, sku } = props
  const { data, loading } = useQuery(StoreConfigDocument)
  const trustedShopsUrl = `${import.meta.graphCommerce.trustedShopsUrl}`

  if (!reviews || loading) return null
  if (!data?.storeConfig?.product_reviews_enabled) return null

  return (
    <Row maxWidth='md' id='reviews'>
      <Box
        sx={{
          pb: 1,
          textTransform: 'uppercase',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant='subtitle1' color='textSecondary' component='h2'>
          {title} ({review_count})
        </Typography>
        <Button variant='text' color='primary' target='_blank' href={trustedShopsUrl}>
          <Trans id='Go to Trusted Shop reviews' />
        </Button>
      </Box>
      <Divider />
      <ProductReviews
        reviews={reviews}
        url_key={url_key ?? ''}
        sku={sku}
        review_count={review_count ?? 0}
      />
    </Row>
  )
}
