import { RichText } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import { extendableComponent } from '@graphcommerce/next-ui'
import { Box, Container, SxProps, Theme, Typography } from '@mui/material'
import renderers from '../../RichText/renderers'
import { RowLinksFragment } from '../RowLinks.gql'

type RowNoticeProps = RowLinksFragment & {
  sx?: SxProps<Theme>
}

const { classes } = extendableComponent('Notice', ['root', 'icon', 'content'] as const)

export function Notice(props: RowNoticeProps) {
  const { sx = [], title, rowLinksCopy, asset } = props

  return (
    <Container
      className={classes.root}
      maxWidth='lg'
      sx={[(theme) => ({ my: theme.spacings.lg }), ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.secondary.light,
          padding: theme.spacings.sm,
          borderRadius: theme.shape.borderRadius,
          boxShadow: theme.shadows[4],
          border: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          alignItems: 'center',
        })}
      >
        <Box
          className={classes.icon}
          sx={(theme) => ({ mr: theme.spacings.sm, position: 'relative' })}
          height={50}
          width={50}
        >
          {asset?.url && (
            <Image src={asset.url} layout='fill' unoptimized={asset.mimeType === 'image/svg+xml'} />
          )}
        </Box>
        <Box className={classes.content}>
          {title && (
            <Typography
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightBold,
              })}
              variant='body1'
            >
              {title}
            </Typography>
          )}
          {rowLinksCopy && <RichText {...rowLinksCopy} sxRenderer={renderers} />}
        </Box>
      </Box>
    </Container>
  )
}
