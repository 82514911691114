import { mergeDeep } from '@graphcommerce/graphql'
import { AddToCartItemSelector } from '@graphcommerce/magento-product'
import { useConfigurableSelectedVariant } from '@graphcommerce/magento-product-configurable'
import { i18n } from '@lingui/core'
import { Box, SxProps, Theme } from '@mui/material'
import { ProductPage2Query } from '../../../graphql/ProductPage2.gql'
import { getProductDeliveryTime } from './getProductDeliveryTime'

type ProductDeliveryTimeProps = {
  sx?: SxProps<Theme>
  label?: string | null
  isAvailable?: boolean
  product?: NonNullable<NonNullable<NonNullable<ProductPage2Query['products']>['items']>[0]>
} & AddToCartItemSelector

export function ProductDeliveryTime(props: ProductDeliveryTimeProps) {
  const { label, product, isAvailable = true, index, sx = [] } = props

  const variant = useConfigurableSelectedVariant({ url_key: product?.url_key, index })
  const p = variant ? mergeDeep(product, variant) : mergeDeep(product)

  if (!p) return null

  const deliveryTimeLabel = label || getProductDeliveryTime(p?.custom_attributes)

  return (
    <Box
      sx={[
        {
          typography: 'subtitle2',
          color: isAvailable ? 'success.main' : 'text.disabled',
          fontWeight: 600,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {isAvailable ? deliveryTimeLabel : i18n._(/* i18n */ 'No longer available')}
    </Box>
  )
}
