import { Asset } from '@graphcommerce/graphcms-ui'
import { breakpointVal } from '@graphcommerce/next-ui'
import { Box, Container, Link } from '@mui/material'
import { ContentLinks } from '../ContentLinks'
import { RowContentLinksFragment } from '../RowContentLinks.gql'

export function ImageTextSlider(props: RowContentLinksFragment) {
  const { title, contentLinks, boxed } = props

  return (
    <Container
      maxWidth='lg'
      sx={(theme) => ({
        marginBottom: { xs: theme.spacings.md, md: theme.spacings.md },

        '& .MuiContainer-root': {
          marginBottom: 0,
        },

        '& .Scroller-root': {
          gap: theme.spacings.xl,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          ...(boxed && {
            border: `1px solid ${theme.palette.divider}`,
            paddingTop: theme.spacings.xs,
            paddingBottom: theme.spacings.xs,
            ...breakpointVal(
              'borderRadius',
              theme.shape.borderRadius * 5,
              theme.shape.borderRadius * 7,
              theme.breakpoints.values,
            ),
          }),
        })}
      >
        <ContentLinks
          title={title}
          sx={{
            '& h2': {
              display: 'none',
            },
          }}
        >
          {contentLinks.map((contentLink) => (
            <Link
              href={contentLink.url}
              key={contentLink.id}
              variant='h6'
              color='inherit'
              underline='hover'
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                rowGap: theme.spacings.xs,
                ...theme.typography.body1,
              })}
            >
              {contentLink?.asset && (
                <Asset
                  asset={contentLink.asset}
                  sx={{ width: { xs: 120, md: 180 }, maxWidth: { xs: 120, md: 180 } }}
                  sizes={{ 0: '120px', 960: '180px' }}
                />
              )}
              {contentLink.title}
            </Link>
          ))}
        </ContentLinks>
      </Box>
    </Container>
  )
}
