import {
  AddProductsToCartForm,
  ProductListItemsFragment,
  UpsellProductsFragment,
} from '@graphcommerce/magento-product'
import { RenderType, responsiveVal, SidebarSlider } from '@graphcommerce/next-ui'
import { SxProps, Theme } from '@mui/material'
import { productListRenderer } from '../../../ProductListItems/productListRenderer'
import { RowProductFragment } from '../RowProduct.gql'

type SwipeableProps = RowProductFragment &
  ProductListItemsFragment &
  UpsellProductsFragment & { sx?: SxProps<Theme> }
export function Swipeable(props: SwipeableProps) {
  const { items, sx = [] } = props

  if (!items || items.length === 0) return null

  return (
    <AddProductsToCartForm>
      <SidebarSlider
        sidebar
        sx={[
          (theme) => ({
            mb: theme.spacings.sm,
            mt: theme.spacings.lg,
            '& .SidebarSlider-sidebar': {
              display: 'none',
            },
            '& .SidebarSlider-grid': {
              display: 'unset',
            },
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {items?.map((item) =>
          item ? (
            <RenderType
              key={item.uid ?? ''}
              renderer={productListRenderer}
              {...item}
              sizes={responsiveVal(214, 400)}
            />
          ) : null,
        )}
      </SidebarSlider>
    </AddProductsToCartForm>
  )
}
