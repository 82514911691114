import { iconChevronDown, IconSvg } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from '@mui/material'
import React from 'react'
import { ProductFAQFragment } from '../../../GraphCMS/RowProduct/variant/ProductFAQ.gql'

export function ProductFAQ(props: ProductFAQFragment) {
  const { amfaq_questions: questions } = props

  if (!questions || !questions.find((v) => v?.status === 1 && v.visibility === 1)) {
    return (
      <Typography variant='subtitle1' component='h4'>
        <Trans id='No questions found' />
      </Typography>
    )
  }

  return (
    <>
      <Divider />
      {questions
        .filter((v) => v?.status === 1 && v.visibility !== 0)
        .map((value, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`${value?.name}_${index}`}>
            <Accordion
              disableGutters
              square
              elevation={0}
              sx={(theme) => ({
                backgroundColor: 'unset',
                py: theme.spacings.xs,
              })}
            >
              <AccordionSummary sx={{ pl: 0 }} expandIcon={<IconSvg src={iconChevronDown} />}>
                <Typography variant='h6' component='h4'>
                  {value?.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 0 }}>
                {value?.answer?.split('\n').map((line) => (
                  <React.Fragment key={line}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        ))}
      <Divider />
    </>
  )
}
