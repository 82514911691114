import {
  CompareProductIdInternalFragment,
  CompareProductToggle,
} from '@graphcommerce/magento-compare'
import { Box } from '@mui/material'

export function ProductListItemCompare(props: CompareProductIdInternalFragment) {
  return (
    <Box
      sx={{
        marginLeft: '-10px',
      }}
    >
      <CompareProductToggle color='default' {...props} product={props} />
    </Box>
  )
}
