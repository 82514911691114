import { RichText } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import { ImageTextBoxed, responsiveVal } from '@graphcommerce/next-ui'
import { Box, Button, SxProps, Theme, Typography, useTheme } from '@mui/material'
import { RowLinksFragment } from '../RowLinks.gql'

type ContactPersonProps = RowLinksFragment & {
  sx?: SxProps<Theme>
}

export function ContactPerson(props: ContactPersonProps) {
  const { sx = [], rowLinksCopy, asset, title, pageLinks } = props

  const theme = useTheme()

  return (
    <ImageTextBoxed
      sx={{
        marginBottom: theme.spacings.sm,
        '& .ImageTextBoxed-wrapper': {
          backgroundColor: 'secondary.light',
          border: 0,
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr 2fr',
          },
        },
        '& .ImageTextBoxed-copy': {
          width: '100%',
          justifyContent: 'center',
        },
        '& .ImageTextBoxed-asset': {
          width: '100%',
          maxHeight: '600px',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      }}
      item={
        asset?.url && (
          <Image
            alt={asset.alt ?? ''}
            width={1532}
            height={1678}
            src={asset.url}
            sizes={{
              0: '100vw',
              [theme.breakpoints.values.md]: responsiveVal(100, 600),
            }}
          />
        )
      }
    >
      {title && (
        <Typography variant='overline' color='textSecondary' sx={{ mb: theme.spacings.sm }}>
          {title}
        </Typography>
      )}
      {rowLinksCopy?.raw && (
        <RichText
          {...rowLinksCopy}
          sxRenderer={{
            paragraph: {
              // For specificity overriding the root paragraph we insert a media query
              '@media (min-width: 0)': {
                fontSize: responsiveVal(20, 40),
                fontWeight: 600,
                lineHeight: 1.2,
                mb: 0,
                color: theme.palette.text.primary,
              },
            },
            link: {
              fontSize: responsiveVal(24, 40),
              fontWeight: 600,
              lineHeight: 1.2,
              mb: 0,
              color: theme.palette.text.primary,
              '&:hover, &:focus, &:active': {
                color: theme.palette.text.primary,
              },
            },
          }}
          withMargin
        />
      )}
      <Box sx={{ mt: theme.spacings.sm, display: 'flex', flexDirection: 'column' }}>
        {pageLinks?.length > 0 &&
          pageLinks.map((pageLink) => (
            <Button
              href={pageLink.url}
              variant='pill'
              size='medium'
              color='primary'
              key={pageLink.url}
              sx={{
                ...theme.typography.body1,
                justifySelf: 'center',
                paddingX: theme.spacings.md,
                marginY: '6px',
              }}
            >
              {pageLink.title}
            </Button>
          ))}
      </Box>
    </ImageTextBoxed>
  )
}
