import { Typography, TypographyVariant } from '@mui/material'
import { useMemo } from 'react'
import { ProductListItemCustomAttributesFragment } from '../ProductListItems/ProductListItemCustomAttributes.gql'

type Props = Pick<ProductListItemCustomAttributesFragment, 'product_usps'> & {
  variant?: TypographyVariant
}

export function ProductUsps(props: Props) {
  const { product_usps, variant = 'subtitle2' } = props

  const formattedProductUsps = useMemo(
    () =>
      product_usps &&
      product_usps
        .replaceAll('\r\n', '')
        .replaceAll(/<\/?ul>|<\/li><li>/g, (match) => (match === '</li><li>' ? ' | ' : ''))
        .replaceAll(/<\/?[^>]+(>|$)/g, ''),
    [product_usps],
  )

  if (!product_usps) return null

  return (
    <Typography
      component='div'
      variant={variant}
      sx={{ color: 'text.secondary', fontWeight: '500', pb: '3px' }}
      className='productUsps'
    >
      {formattedProductUsps}
    </Typography>
  )
}
