import { Asset, RichText } from '@graphcommerce/graphcms-ui'
import { breakpointVal } from '@graphcommerce/next-ui'
import { Box, Container } from '@mui/material'
import { ContentLinks } from '../ContentLinks'
import { RowContentLinksFragment } from '../RowContentLinks.gql'

export function CheckList(props: RowContentLinksFragment) {
  const { title, contentLinks, boxed } = props

  return (
    <Container
      maxWidth={false}
      sx={(theme) => ({
        display: { xs: 'none', md: 'block' },
        mb: theme.spacings.xl,

        '& .MuiContainer-root': {
          mb: 0,
        },

        '& .Scroller-root': {
          alignItems: 'center',
          gap: { md: `calc(${theme.spacings.xs} * 0.8)`, lg: theme.spacings.md },
          justifyContent: 'center',
        },

        '& .MuiFab-root': {
          display: 'inline-flex',
          zIndex: 'inherit',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          ...(boxed && {
            border: `1px solid ${theme.palette.divider}`,
            py: theme.spacings.sm,
            px: { xs: theme.spacings.xs, lg: theme.spacings.sm },
            ...breakpointVal(
              'borderRadius',
              theme.shape.borderRadius * 5,
              theme.shape.borderRadius * 7,
              theme.breakpoints.values,
            ),
          }),
        })}
      >
        <ContentLinks
          title={title}
          maxWidth={false}
          sx={{
            '& h2': {
              display: 'none',
            },

            '& .ContentLinks-centerLeft': {
              left: 0,
            },

            '& .ContentLinks-centerRight': {
              right: 0,
            },
          }}
        >
          {contentLinks.map((contentLink, index) => (
            <Box
              key={contentLink.id}
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'nowrap',
                whiteSpace: 'nowrap',
                color: 'inherit',
                gap: '2px',

                ...(index > 2 && {
                  [theme.breakpoints.down('lg')]: {
                    display: 'none',
                  },
                }),
              })}
            >
              {contentLink.asset && (
                <Box sx={{ flexShrink: 0 }}>
                  <Asset
                    asset={contentLink.asset}
                    layout='fill'
                    sizes={{ 0: '15px' }}
                    sx={{
                      color: 'text.primary',
                      maxHeight: 10,
                      marginRight: { xs: '3px', md: '5px', lg: '12px' },
                      objectFit: 'contain',
                      width: 'auto !important',
                      minHeight: { md: '8px', lg: '10px' },
                    }}
                  />
                </Box>
              )}
              {contentLink.description && (
                <RichText
                  raw={contentLink.description?.raw}
                  sxRenderer={{
                    paragraph: (theme) => ({
                      ...theme.typography.body1,
                      flex: '0 0 auto',
                      '@media (min-width: 960px) and (max-width: 1010px)': {
                        fontSize: 14,
                      },
                    }),
                    link: (theme) => ({
                      ...theme.typography.body1,
                      textDecoration: 'underline',
                      color: 'inherit',
                      '@media (min-width: 960px) and (max-width: 1010px)': {
                        fontSize: 14,
                      },
                    }),
                    image: (theme) => ({
                      height: '32px',
                      '& img': {
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      },
                    }),
                  }}
                />
              )}
            </Box>
          ))}
        </ContentLinks>
      </Box>
    </Container>
  )
}
