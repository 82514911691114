import {
  AddProductsToCartFab,
  ProductListItemRenderer,
  ProductListItemSkeleton,
} from '@graphcommerce/magento-product'
import { ProductReviewSummary } from '@graphcommerce/magento-review'
import { ProductWishlistIconButton } from '@graphcommerce/magento-wishlist'
import { iconCartAdd } from '@graphcommerce/next-ui'
import { alpha } from '@mui/material'
import { ProductListItem } from './ProductListItem'
import { ProductListItemCompare } from './ProductListItemCompare'
import { clientPriceCalculation } from './helpers/clientPriceCalculation'
import { ProductListItemConfigurable } from './variant'

export const productListRenderer: ProductListItemRenderer = {
  Skeleton: ProductListItemSkeleton,
  SimpleProduct: (props) => {
    const { sku, price_range, is_eol, stock_status, show_price_online, sx } = props
    const isEol = Boolean(is_eol && stock_status === 'OUT_OF_STOCK')
    const showPriceOnline = Boolean(show_price_online && !isEol)

    return (
      <ProductListItem
        {...props}
        price_range={clientPriceCalculation(price_range)}
        aspectRatio={[4, 3]}
        bottomLeft={
          <ProductReviewSummary
            {...props}
            sx={(theme) => ({
              '.ProductReviewSummary-iconStar': { fill: theme.palette.success.main },
            })}
          />
        }
        topRight={<ProductWishlistIconButton {...props} />}
        bottomRight={
          <AddProductsToCartFab
            sku={sku}
            size='small'
            color='success'
            sx={(theme) => ({
              '&.MuiButtonBase-root': {
                color: 'primary.contrastText',
                '& .MuiCircularProgress-root': {
                  color: 'currentColor',
                },
                '&:hover, &:focus, &:active': {
                  backgroundColor: alpha(theme.palette.success.main, 0.1),
                  color: 'success.main',
                  '& .MuiCircularProgress-root': {
                    color: 'currentColor',
                  },
                },
              },
            })}
            icon={iconCartAdd}
            disabled={!showPriceOnline}
          />
        }
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
      >
        <ProductListItemCompare {...props} />
      </ProductListItem>
    )
  },
  ConfigurableProduct: (props) => {
    const { price_range, sx } = props
    return (
      <ProductListItemConfigurable
        {...props}
        aspectRatio={[4, 3]}
        price_range={clientPriceCalculation(price_range)}
        swatchLocations={{
          topLeft: [],
          topRight: [],
          bottomLeft: [],
          bottomRight: [],
        }}
        bottomLeft={
          <ProductReviewSummary
            {...props}
            sx={(theme) => ({
              '.ProductReviewSummary-iconStar': { fill: theme.palette.success.main },
            })}
          />
        }
        topRight={<ProductWishlistIconButton {...props} />}
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
      />
    )
  },
  BundleProduct: (props) => {
    const { price_range, sx } = props
    return (
      <ProductListItem
        {...props}
        aspectRatio={[4, 3]}
        price_range={clientPriceCalculation(price_range)}
        bottomLeft={
          <ProductReviewSummary
            {...props}
            sx={(theme) => ({
              '.ProductReviewSummary-iconStar': { fill: theme.palette.success.main },
            })}
          />
        }
        topRight={<ProductWishlistIconButton {...props} />}
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
      >
        <ProductListItemCompare {...props} />
      </ProductListItem>
    )
  },
  VirtualProduct: (props) => {
    const { sku, price_range, sx } = props
    return (
      <ProductListItem
        {...props}
        aspectRatio={[4, 3]}
        price_range={clientPriceCalculation(price_range)}
        bottomLeft={
          <ProductReviewSummary
            {...props}
            sx={(theme) => ({
              '.ProductReviewSummary-iconStar': { fill: theme.palette.success.main },
            })}
          />
        }
        topRight={<ProductWishlistIconButton {...props} />}
        bottomRight={<AddProductsToCartFab sku={sku} size='small' />}
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
      >
        <ProductListItemCompare {...props} />
      </ProductListItem>
    )
  },
  DownloadableProduct: (props) => {
    const { price_range, sx } = props
    return (
      <ProductListItem
        {...props}
        aspectRatio={[4, 3]}
        price_range={clientPriceCalculation(price_range)}
        bottomLeft={
          <ProductReviewSummary
            {...props}
            sx={(theme) => ({
              '.ProductReviewSummary-iconStar': { fill: theme.palette.success.main },
            })}
          />
        }
        topRight={<ProductWishlistIconButton {...props} />}
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
      >
        <ProductListItemCompare {...props} />
      </ProductListItem>
    )
  },
  GroupedProduct: (props) => {
    const { price_range, sx } = props
    return (
      <ProductListItem
        {...props}
        aspectRatio={[4, 3]}
        price_range={clientPriceCalculation(price_range)}
        bottomLeft={
          <ProductReviewSummary
            {...props}
            sx={(theme) => ({
              '.ProductReviewSummary-iconStar': { fill: theme.palette.success.main },
            })}
          />
        }
        topRight={<ProductWishlistIconButton {...props} />}
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
      >
        <ProductListItemCompare {...props} />
      </ProductListItem>
    )
  },
}
