import { ProductListItemProps } from '@graphcommerce/magento-product'
import { ProductCustomAttributesFragment } from '../../ProductListItems/parts/ProductCustomAttributes.gql'

export const getProductDeliveryTime = (
  custom_attributes: Extract<
    ProductListItemProps,
    ProductCustomAttributesFragment
  >['custom_attributes'],
) => {
  if (!custom_attributes) return null

  const label = custom_attributes.find(
    (props) => props?.attribute_metadata?.code === 'delivery_time',
  )?.selected_attribute_options?.attribute_option?.[0]?.label

  return label
}
