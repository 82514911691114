import { PriceRange } from '@graphcommerce/graphql-mesh'
import { exclTax, inclTax } from '../../../lib/prices-incl-excl-vat/resolver'

export function clientPriceCalculation(price_range: PriceRange): PriceRange {
  return price_range.minimum_price.final_price_excl && price_range.minimum_price.final_price_incl
    ? price_range
    : {
        maximum_price: price_range.maximum_price,
        minimum_price: {
          ...price_range.minimum_price,
          final_price_excl: exclTax(price_range.minimum_price.final_price),
          final_price_incl: inclTax(price_range.minimum_price.final_price),
          regular_price_excl: exclTax(price_range.minimum_price.regular_price),
          regular_price_incl: inclTax(price_range.minimum_price.regular_price),
        },
      }
}
