import { ScrollerProvider, Scroller, ScrollerDots } from '@graphcommerce/framer-scroller'
import { breakpointVal, extendableComponent } from '@graphcommerce/next-ui'
import { Box, Container, ContainerProps, SxProps, Theme } from '@mui/material'
import { AutoScroll } from './Autoscroll'
import { BannerSlide } from './BannerSlide'
import { RowBannerSliderFragment } from './RowBannerSlider.gql'

type RowBannerSliderProps = RowBannerSliderFragment &
  ContainerProps & {
    sx?: SxProps<Theme>
    autoScroll?: boolean
  }

const compName = 'RowBannerSlider' as const
const parts = ['root'] as const
const { classes } = extendableComponent(compName, parts)

export function RowBannerSlider(props: RowBannerSliderProps) {
  const { bannerSlides, maxWidth, sx = [], autoScroll = true } = props

  return (
    <Container
      className={classes.root}
      maxWidth={maxWidth}
      sx={[
        (theme) => ({
          mt: theme.spacings.xs,
          mb: { xs: theme.spacings.xxl, md: theme.spacings.sm },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={(theme) => ({
          position: 'relative',
          '& .Scroller-root': {
            gap: theme.spacings.md,
            height: 'auto',
          },
        })}
      >
        <ScrollerProvider>
          <Scroller
            hideScrollbar
            sx={(theme) => ({
              height: '60vh',
              gridAutoColumns: '100%',
              maxHeight: { sx: 400, md: 550 },
              ...breakpointVal(
                'borderRadius',
                theme.shape.borderRadius * 4,
                theme.shape.borderRadius * 7,
                theme.breakpoints.values,
              ),
            })}
          >
            {bannerSlides.map((slide, index) => (
              <BannerSlide key={slide.imageDesktop.url} {...slide} index={index} />
            ))}
          </Scroller>
          <Box
            sx={(theme) => ({
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'center',
              position: 'absolute',
              bottom: theme.spacings.sm,
              left: 0,
              right: 0,
              zIndex: 2,
              width: 'max-content',
              mx: 'auto',
            })}
          >
            {bannerSlides.length > 1 && (
              <Box
                sx={(theme) => ({
                  height: 43,
                  alignContent: 'center',
                  background: theme.palette.background.paper,
                  boxShadow: theme.shadows[3],
                  borderRadius: 999,
                  display: 'flex',
                  alignItems: 'center',
                  [theme.breakpoints.down('sm')]: {
                    transform: 'scale(0.75)',
                  },

                  '& .ScrollerDots-root': {
                    mx: 0,
                  },

                  '& .ScrollerDot-circle': {
                    width: { xs: 10, sm: 10, md: 10 },
                    height: { xs: 10, sm: 10, md: 10 },
                  },
                })}
              >
                <ScrollerDots />
              </Box>
            )}
          </Box>
          {autoScroll && <AutoScroll timePerSlide={5000} />}
        </ScrollerProvider>
      </Box>
    </Container>
  )
}
