import { IconSvg, BlogContent, iconChevronRight } from '@graphcommerce/next-ui'
import { Button, SxProps, Theme } from '@mui/material'
import { RowButtonFragment } from './RowButton.gql'

type RowButtonProps = RowButtonFragment & {
  sx?: SxProps<Theme>
}

export function RowButton(props: RowButtonProps) {
  const { sx = [], button } = props
  const { url, title, target } = button ?? {}

  return (
    <BlogContent sx={sx}>
      {title && (
        <Button
          target={target ? `_${target}` : '_self'}
          href={url as string}
          variant='pill'
          color='primary'
          size='medium'
          endIcon={<IconSvg key='icon' src={iconChevronRight} size='inherit' sx={{ ml: '40px' }} />}
        >
          {title}
        </Button>
      )}
    </BlogContent>
  )
}
