import { useQuery } from '@graphcommerce/graphql'
import { ProductListItem_ConfigurableProduct_Fragment } from '@graphcommerce/magento-product'
import { DeliveryTimeOptionsDocument } from '../parts/DeliveryTimeOptions.gql'

type useOptimalDeliveryTimeProps = {
  variants: ProductListItem_ConfigurableProduct_Fragment['variants']
}

export function useOptimalDeliveryTime(props: useOptimalDeliveryTimeProps) {
  const { variants } = props
  const { data } = useQuery(DeliveryTimeOptionsDocument)

  const deliveryOptionRankingList = data?.deliveryTimeOptions?.items?.[0]?.attribute_options?.map(
    (option) => option?.label,
  )

  if (!deliveryOptionRankingList) return { configurableDeliveryTimeLabel: null }

  const variantLabels: string[] = []

  variants?.forEach((variant) => {
    const customAttributes = variant?.product?.custom_attributes
    const deliveryTimeAttribute = customAttributes?.find(
      (attr) => attr?.attribute_metadata?.code === 'delivery_time',
    )
    const label = deliveryTimeAttribute?.selected_attribute_options?.attribute_option?.[0]
      ?.label as string

    if (label) variantLabels.push(label)
  })

  // Find the variantLabel with the lowest index in deliveryOptionRankingList
  let lowestIndex = deliveryOptionRankingList.length // Start with a high value
  let optimalDeliveryTimeLabel: string | null = null

  variantLabels.forEach((label) => {
    const index = deliveryOptionRankingList.indexOf(label)
    if (index !== -1 && index < lowestIndex) {
      lowestIndex = index
      optimalDeliveryTimeLabel = label
    }
  })

  return {
    optimalDeliveryTimeLabel,
  }
}
