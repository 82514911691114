import { useViewItemList } from '@graphcommerce/google-datalayer/components/DatalayerViewItemList'
import { Image, ImageProps } from '@graphcommerce/image'
import { ProductListItemFragment, useProductLink } from '@graphcommerce/magento-product'
import { responsiveVal, extendableComponent, breakpointVal } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import {
  ButtonBase,
  Typography,
  Box,
  styled,
  SxProps,
  Theme,
  useEventCallback,
} from '@mui/material'
import React from 'react'
import { ProductDeliveryTime } from '../ProductPage/ProductDeliveryTime/ProductDeliveryTime'
import { getProductDeliveryTime } from '../ProductPage/ProductDeliveryTime/getProductDeliveryTime'
import { ProductUsps } from '../ProductPage/ProductUsps'
import { ProductListPrice } from './ProductListPrice'
import { ProductLabel } from './parts/ProductLabel'
import { ProductLabelImage } from './parts/ProductLabelImage'

const { classes, selectors } = extendableComponent('ProductListItem', [
  'root',
  'item',
  'title',
  'titleContainer',
  'usps',
  'stock',
  'price',
  'overlayItems',
  'topLeft',
  'topRight',
  'bottomLeft',
  'bottomRight',
  'imageContainer',
  'placeholder',
  'image',
  'taxlabel',
] as const)

export type OverlayAreaKeys = 'topLeft' | 'bottomLeft' | 'topRight' | 'bottomRight'
export type OverlayAreas = Partial<Record<OverlayAreaKeys, React.ReactNode>>

type StyleProps = {
  aspectRatio?: [number, number]
  imageOnly?: boolean
}

type BaseProps = { subTitle?: React.ReactNode; children?: React.ReactNode } & StyleProps &
  OverlayAreas &
  ProductListItemFragment &
  Pick<ImageProps, 'loading' | 'sizes' | 'dontReportWronglySizedImages'>

export type ProductListItemProps = BaseProps & {
  sx?: SxProps<Theme>
  titleComponent?: React.ElementType
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>, item: ProductListItemFragment) => void
  configurableDeliveryTimeLabel?: string
}

const StyledImage = styled(Image)({})

export function ProductListItem(props: ProductListItemProps) {
  const {
    __typename,
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    small_image,
    name,
    price_range,
    children,
    imageOnly = false,
    loading,
    sizes,
    dontReportWronglySizedImages,
    aspectRatio = [4, 3],
    product_usps,
    product_label,
    label_image,
    custom_attributes,
    show_price_online,
    is_eol,
    stock_status,
    titleComponent = 'h2',
    sx = [],
    onClick,
    configurableDeliveryTimeLabel,
  } = props

  const isEol = Boolean(is_eol && stock_status === 'OUT_OF_STOCK')
  const showPriceOnline = Boolean(show_price_online && !isEol)
  const selectItem = useViewItemList()

  const handleClick = useEventCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    const { sku } = props
    if (sku) selectItem(sku)
    onClick?.(e, props)
  })

  const productLink = useProductLink(props)

  const deliveryTimeLabel =
    getProductDeliveryTime(custom_attributes) ?? configurableDeliveryTimeLabel

  const additionalTitle = custom_attributes?.find(
    (attribute) => attribute?.attribute_metadata?.code === 'erp_pnameadd',
  )?.entered_attribute_value?.value

  return (
    <ButtonBase
      href={productLink}
      sx={[
        (theme) => ({
          display: 'grid',
          gridTemplateRows: { xs: 'auto 1fr auto' },
          gridTemplateColumns: 'inherit',
          position: 'relative',
          height: 'auto',
          justifyContent: 'inherit',
          ...breakpointVal(
            'borderRadius',
            theme.shape.borderRadius * 2,
            theme.shape.borderRadius * 3,
            theme.breakpoints.values,
          ),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      className={classes.root}
      onClick={handleClick}
    >
      <Box
        sx={(theme) => ({
          display: 'grid',
          backgroundColor: 'background.image',
          ...breakpointVal(
            'borderRadius',
            theme.shape.borderRadius * 2,
            theme.shape.borderRadius * 3,
            theme.breakpoints.values,
          ),
          overflow: 'hidden',
          p: theme.spacings.sm,
          '& > picture': {
            gridArea: `1 / 1 / 3 / 3`,
            margin: `calc(${responsiveVal(8, 12)} * -1)`,
          },
          '& img': {
            mixBlendMode: 'multiply',
          },
        })}
        className={classes.imageContainer}
      >
        {small_image ? (
          <StyledImage
            layout='fill'
            width={1}
            height={1}
            sizes={sizes}
            dontReportWronglySizedImages={dontReportWronglySizedImages}
            src={small_image.url ?? ''}
            alt={small_image.label ?? ''}
            className={classes.image}
            loading={loading}
            sx={(theme) => ({
              objectFit: 'contain',
              aspectRatio: `${aspectRatio[0] / aspectRatio[1]}`,
              p: theme.spacings.sm,
            })}
          />
        ) : (
          <Box
            sx={{
              gridArea: `1 / 1 / 3 / 3`,
              typography: 'caption',
              display: 'flex',
              textAlign: 'center',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'background.default',
              userSelect: 'none',
            }}
            className={`${classes.placeholder} ${classes.image}`}
          >
            <Trans id='No Image' />
          </Box>
        )}

        {!imageOnly && (
          <>
            <Box
              sx={{
                gridArea: `1 / 1 / 2 / 2`,
                zIndex: 1,
              }}
              className={classes.topLeft}
            >
              {product_label && <ProductLabel product_label={product_label} />}
              {label_image && (
                <ProductLabelImage
                  label_image={label_image}
                  sx={{
                    width: 'auto',
                    height: 'auto',
                    maxWidth: '80px',
                    maxHeight: responsiveVal(40, 60),
                    objectFit: 'contain',
                    objectPosition: 'left',
                  }}
                />
              )}
              {topLeft}
            </Box>
            <Box
              sx={{
                justifySelf: 'end',
                textAlign: 'right',
                gridArea: `1 / 2 / 2 / 3`,
                zIndex: 1,
              }}
              className={classes.topLeft}
            >
              {topRight}
            </Box>
            <Box
              sx={{ alignSelf: 'flex-end', gridArea: `2 / 1 / 3 / 2`, zIndex: 1 }}
              className={classes.bottomLeft}
            >
              {bottomLeft}
            </Box>
            <Box
              sx={{
                textAlign: 'right',
                alignSelf: 'flex-end',
                gridArea: `2 / 2 / 3 / 3`,
                zIndex: 1,
                justifySelf: 'end',
              }}
              className={classes.bottomRight}
            >
              {bottomRight}
            </Box>
          </>
        )}
      </Box>

      {!imageOnly && (
        <Box className={classes.titleContainer} sx={{ pt: 2, alignSelf: 'start' }}>
          <Typography
            component={titleComponent}
            variant='subtitle1'
            color='primary'
            className={classes.title}
            sx={{ fontVariationSettings: "'wght' 600", fontWeight: '600' }}
          >
            {name}
          </Typography>

          {additionalTitle && (
            <Typography
              component='h3'
              variant='subtitle2'
              className={classes.title}
              sx={{ fontVariationSettings: "'wght' 500", fontWeight: '500' }}
            >
              {additionalTitle}
            </Typography>
          )}
          <ProductUsps product_usps={product_usps} />
          {showPriceOnline && (
            <Box>
              {__typename === 'ConfigurableProduct' && (
                <Typography variant='caption'>
                  <Trans id='From:' />
                </Typography>
              )}
              <ProductListPrice {...price_range.minimum_price} />
            </Box>
          )}
        </Box>
      )}

      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'column',
          justifyContent: 'space-between',
          mt: { xs: 1, md: 0 },
          pb: 1,
          pr: '12px',
        }}
      >
        <Box className={classes.stock} sx={{ alignSelf: 'end' }}>
          <ProductDeliveryTime
            label={deliveryTimeLabel}
            sx={{ fontSize: { xs: 12, md: 14 } }}
            isAvailable={!isEol}
          />
        </Box>
      </Box>
      {children}
    </ButtonBase>
  )
}

ProductListItem.selectors = { ...selectors, ...ProductListPrice.selectors }
