import { useQuery } from '@graphcommerce/graphql'
import { Row } from '@graphcommerce/next-ui'
import { Container } from '@mui/material'
import { useMemo } from 'react'
import { EventCalendarItem } from '../../Events/EventCalendarItem'
import { GetEventsDocument } from './GetEvents.gql'
import { RowEventCalendarFragment } from './RowEventCalendar.gql'

type EventCalendarProps = RowEventCalendarFragment

export function RowEventCalendar(props: EventCalendarProps) {
  const { events } = props
  const currentDate = useMemo(() => new Date().toISOString(), [])

  // TODO: NEXTJS@13 fetch revalidation
  const { data, error } = useQuery(GetEventsDocument, {
    variables: { currentDate },
    fetchPolicy: 'cache-first',
  })

  if (events.length === 0 && !data) return null

  if (error) {
    console.error('GraphQL Query Error: ', error)
    return <p>Error loading events</p>
  }

  const renderEventItem = (event: (typeof events)[0]) => {
    if (event?.pages?.length === 0) return null
    return <EventCalendarItem key={event.pages[0].url} content={[event]} {...event.pages[0]} />
  }

  return (
    <Row
      sx={(theme) => ({
        typography: 'subtitle1',
        color: 'text.secondary',
        margin: `${theme.spacings.lg} auto`,
      })}
    >
      <Container
        sx={(theme) => ({
          mt: theme.spacings.xl,
          mb: theme.spacings.xl,
        })}
      >
        {events.map(renderEventItem)}
        {events.length < 1 && data?.events?.map(renderEventItem)}
      </Container>
    </Row>
  )
}
