import { Image } from '@graphcommerce/image'
import { SxProps, Theme } from '@mui/material'
import { ProductLabelImageFragment } from '../../../graphql/ProductLabelImage.gql'

export function ProductLabelImage(props: ProductLabelImageFragment & { sx?: SxProps<Theme> }) {
  const { label_image, sx } = props

  if (!label_image) return null

  return <Image src={label_image} layout='fill' width={150} height={150} sx={sx} />
}
