import { Asset } from '@graphcommerce/graphcms-ui'
import { breakpointVal } from '@graphcommerce/next-ui'
import { Box, Container, Link } from '@mui/material'
import { ContentLinks } from '../ContentLinks'
import { RowContentLinksFragment } from '../RowContentLinks.gql'

export function ImageSlider(props: RowContentLinksFragment) {
  const { title, contentLinks, boxed } = props

  return (
    <Container
      maxWidth={false}
      sx={(theme) => ({
        mb: theme.spacings.xxl,

        '& .MuiContainer-root': {
          mb: 0,
        },

        '& .Scroller-root': {
          alignItems: 'center',
          gap: theme.spacings.xxl,
        },

        '& .MuiFab-root': {
          display: 'inline-flex',
          zIndex: 'inherit',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          ...(boxed && {
            border: `1px solid ${theme.palette.divider}`,
            py: theme.spacings.xl,
            px: theme.spacings.lg,
            ...breakpointVal(
              'borderRadius',
              theme.shape.borderRadius * 5,
              theme.shape.borderRadius * 7,
              theme.breakpoints.values,
            ),
          }),
        })}
      >
        <ContentLinks
          title={title}
          maxWidth={false}
          sx={(theme) => ({
            '& h2': {
              display: 'none',
            },

            '& .ContentLinks-centerLeft': {
              left: { xs: `calc(${theme.spacings.sm} * -0.25)`, md: theme.spacings.sm },
            },

            '& .ContentLinks-centerRight': {
              right: { xs: `calc(${theme.spacings.sm} * -0.25)`, md: theme.spacings.sm },
            },
          })}
        >
          {contentLinks.map((contentLink) => (
            <Link
              href={contentLink.url}
              key={contentLink.id}
              variant='h6'
              color='inherit'
              underline='hover'
            >
              {contentLink?.asset && (
                <Asset
                  asset={contentLink.asset}
                  sx={{
                    width: () => {
                      const widthBase = 60
                      const scaleFactor = 0.525
                      const naturalWidth = contentLink?.asset?.width || 0
                      const naturalHeight = contentLink?.asset?.height || 0
                      const imageRatio = naturalWidth / naturalHeight
                      const width = imageRatio ** scaleFactor * widthBase
                      return { xs: width * 0.65, sm: width * 0.8, md: width * 0.9, lg: width }
                    },
                    display: 'block',
                    filter: 'brightness(0)',
                  }}
                  sizes={{ 0: '120px', 960: '240px' }}
                />
              )}
            </Link>
          ))}
        </ContentLinks>
      </Box>
    </Container>
  )
}
