import { Row } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import { ProductCustomAttributesFragment } from '../../../ProductListItems/parts/ProductCustomAttributes.gql'
import { RowProductFragment } from '../RowProduct.gql'
import { excludedAttributes } from '../constants/specsConstants'

type SpecsProps = RowProductFragment & Partial<ProductCustomAttributesFragment>

export function Specs(props: SpecsProps) {
  const { custom_attributes } = props

  // filter excludedAttributes and is_visible_on_front from custom_attributes
  const attributes =
    custom_attributes?.filter(
      (attribute) =>
        !excludedAttributes.includes(attribute?.attribute_metadata?.code ?? '') &&
        attribute?.attribute_metadata?.is_visible_on_front,
    ) ?? []

  return (
    <Row maxWidth='lg'>
      <Typography
        variant='subtitle1'
        color='textSecondary'
        component='h2'
        sx={{ pb: 1, textTransform: 'uppercase' }}
      >
        <Trans id='Product Details' />
      </Typography>
      <Divider />
      {attributes.length < 1 ? (
        <Typography variant='subtitle1' color='textSecondary'>
          <Trans id='No attributes available' />
        </Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableBody>
              {attributes.map((attribute) => (
                <TableRow
                  sx={(theme) => ({
                    '& .MuiTableCell-root': {
                      borderColor: theme.palette.divider,
                      padding: { xs: '6px', md: '8px' },
                    },
                  })}
                  key={attribute?.attribute_metadata?.code}
                  hover
                >
                  <TableCell>
                    <Typography
                      variant='subtitle2'
                      component='h3'
                      sx={(theme) => ({
                        fontWeight: theme.typography.h1.fontWeight,
                        fontVariationSettings: theme.typography.h1.fontVariationSettings,
                      })}
                    >
                      {attribute?.attribute_metadata?.label}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body1'>
                      {attribute?.entered_attribute_value?.value}
                      {attribute?.selected_attribute_options?.attribute_option
                        ?.map((option) => option?.label)
                        .join(', ')}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Row>
  )
}
