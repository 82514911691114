import { Asset } from '@graphcommerce/graphcms-ui'
import { iconChevronRight, IconSvg, findByTypename } from '@graphcommerce/next-ui'
import { Box, Typography, useTheme, Link } from '@mui/material'
import { EventItemFragment } from './EventItem.gql'

type EventCalendarItemProps = EventItemFragment
type FormattedDate = {
  time: string
  date: string
}

const formatDateAndTime = (date: unknown): FormattedDate | undefined => {
  const dateTime = typeof date === 'string' ? new Date(date) : undefined

  if (dateTime && !Number.isNaN(dateTime.getTime())) {
    return {
      time: dateTime.toLocaleTimeString(['nl-NL'], { hour: '2-digit', minute: '2-digit' }),
      date: dateTime.toLocaleDateString(['nl-NL'], {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      }),
    }
  }
  return undefined
}

export function EventCalendarItem(props: EventCalendarItemProps) {
  const { title, asset, content, url } = props
  const eventData = findByTypename(content, 'RowEvent')
  const theme = useTheme()

  // Format the start and end dates and times
  const startDate = eventData?.eventDateTime
    ? formatDateAndTime(eventData?.eventDateTime)
    : undefined
  const endDate = formatDateAndTime(eventData?.eventEndDateTime)

  // Check if the start and end dates are the same
  const isSameDay = startDate && endDate && startDate.date === endDate.date

  let eventTime = ''

  // Build the eventTime string
  if (startDate?.date && startDate?.time) {
    eventTime = `${startDate.date}, ${startDate.time}`

    // Include the end time if the event is the same day and has an end time
    if (isSameDay && endDate?.time) {
      eventTime += ` - ${endDate.time}`
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'grid',
        rowGap: 0,
        width: '100%',
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:first-of-type': {
          borderTop: `1px solid ${theme.palette.divider}`,
        },
        [theme.breakpoints.up('md')]: {
          py: '20px',
          gridTemplateColumns: '2fr 10fr',
        },
        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: '4fr 8fr',
          py: '25px',
          columnGap: '25px',
        },
      }}
    >
      <Link
        href={`/${url}`}
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      />
      <Box
        sx={{
          height: '100px',
          width: '150px',
          lineHeight: 0,
        }}
      >
        {asset && (
          <Asset
            asset={{ ...asset, width: 150, height: 100 }}
            sizes={{ 0: '150px' }}
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: '8px',
              objectFit: 'cover',
            }}
            layout='fill'
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          [theme.breakpoints.up('md')]: {
            width: '100%',
            height: '100%',
          },
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        }}
      >
        <Typography
          component='span'
          variant='body1'
          sx={{
            width: '100%',
            color: theme.palette.text.primary,
            display: 'flex',
            alignItems: 'center',
            visibility: eventTime ? 'visible' : 'hidden',
            paddingX: { md: '2rem' },
            [theme.breakpoints.down('md')]: {
              order: 2,
            },
          }}
        >
          {eventTime}
        </Typography>
        <Typography
          component='h3'
          variant='h5'
          sx={{
            width: '100%',
            color: theme.palette.text.primary,
            display: 'flex',
            alignItems: 'center',
            visibility: title ? 'visible' : 'hidden',
            paddingX: { md: '2rem' },
            [theme.breakpoints.down('md')]: {
              order: 1,
            },
          }}
        >
          {title}
        </Typography>
        <Typography
          component='span'
          variant='body1'
          sx={{
            width: '100%',
            color: theme.palette.text.primary,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            visibility: eventData?.eventLocation ? 'visible' : 'hidden',
            paddingX: { md: '2rem' },
            [theme.breakpoints.down('md')]: {
              order: 3,
            },
          }}
        >
          {eventData?.eventLocation}
          <IconSvg
            src={iconChevronRight}
            sx={{ [theme.breakpoints.down('md')]: { display: 'none' } }}
          />
        </Typography>
      </Box>
    </Box>
  )
}
