import { SxProps, Theme } from '@mui/material'
import { Table } from '../../Table/Table'
import { RowEventFragment } from './RowEvent.gql'

type RowEventProps = RowEventFragment & {
  sx?: SxProps<Theme>
}

export function RowEvent(props: RowEventProps) {
  const { sx = [], eventDateTime, eventEndDateTime, eventLocation, eventPrice } = props

  const dateTime = new Date(eventDateTime as string)
  const endDateTime = new Date(eventEndDateTime as string)

  const startDate = {
    startTime: dateTime.toLocaleTimeString(['nl-NL'], { hour: '2-digit', minute: '2-digit' }),
    startDate: dateTime.toLocaleDateString(['nl-NL'], {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }),
  }

  const endDate = {
    endTime: endDateTime.toLocaleTimeString(['nl-NL'], { hour: '2-digit', minute: '2-digit' }),
    endDate: endDateTime.toLocaleDateString(['nl-NL'], {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }),
  }

  // Conditionally render an endtime or enddate, depending on the duration of the event
  const isSameDay = dateTime.toLocaleDateString() === endDateTime.toLocaleDateString()

  const eventOrder = !!(dateTime.valueOf() <= endDateTime.valueOf())

  const eventDetails = [
    {
      label: 'Date',
      value:
        isSameDay || !eventOrder
          ? startDate.startDate
          : `${startDate.startDate} - ${endDate.endDate}`,
    },
    { label: 'Location', value: eventLocation },
    {
      label: 'Time',
      value: isSameDay ? `${startDate.startTime} - ${endDate.endTime}` : startDate.startTime,
    },
    {
      label: 'Price',
      value: eventPrice,
    },
  ]

  return <Table sx={sx} items={eventDetails} />
}
