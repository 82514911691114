/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export const DeliveryTimeOptionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DeliveryTimeOptions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"deliveryTimeOptions"},"name":{"kind":"Name","value":"customAttributeMetadata"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"attributes"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"attribute_code"},"value":{"kind":"StringValue","value":"delivery_time","block":false}},{"kind":"ObjectField","name":{"kind":"Name","value":"entity_type"},"value":{"kind":"StringValue","value":"catalog_product","block":false}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"attribute_code"}},{"kind":"Field","name":{"kind":"Name","value":"attribute_options"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]}}]} as unknown as DocumentNode<DeliveryTimeOptionsQuery, DeliveryTimeOptionsQueryVariables>;
export type DeliveryTimeOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DeliveryTimeOptionsQuery = { deliveryTimeOptions?: { items?: Array<{ attribute_code?: string | null, attribute_options?: Array<{ label?: string | null, value?: string | null } | null> | null } | null> | null } | null };
