import { breakpointVal } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Box, Button, Container, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import { ProductFAQ } from '../../../ProductPage/FAQ/CreateProductFAQForm/ProductFAQ'
import { RowProductFragment } from '../RowProduct.gql'
import { ProductFAQFragment } from './ProductFAQ.gql'

export type FAQProps = RowProductFragment & ProductFAQFragment

export function FAQ(props: FAQProps) {
  const { title, amfaq_questions, url_key } = props
  const router = useRouter()

  return (
    <Container id='faq' sx={(theme) => ({ mb: theme.spacings.xxl })}>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.background.default,
          p: theme.spacings.lg,
          ...breakpointVal(
            'borderRadius',
            theme.shape.borderRadius * 5,
            theme.shape.borderRadius * 7,
            theme.breakpoints.values,
          ),
        })}
      >
        <Typography variant='overline' color='textSecondary'>
          <Trans id='About this product' />
        </Typography>
        <Typography variant='h3' sx={(theme) => ({ mb: theme.spacings.sm, mt: '0.5em' })}>
          {title}
        </Typography>

        <ProductFAQ amfaq_questions={amfaq_questions} />

        <Button
          sx={(theme) => ({ mt: theme.spacings.sm })}
          variant='pill'
          color='primary'
          size='medium'
          onClick={() => router.push(`/account/faq/add?url_key=${url_key}`)}
        >
          <Trans id='Ask a question' />
        </Button>
      </Box>
    </Container>
  )
}
