import { ProductListItemsFragment } from '@graphcommerce/magento-product'
import { ButtonLinkList, IconSvg, nonNullable } from '@graphcommerce/next-ui'
import iconDownload from '@graphcommerce/next-ui/icons/download.svg'
import { Button, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { RowProductFragment } from '../RowProduct.gql'

type DownloadsProps = RowProductFragment & ProductListItemsFragment

export function Downloads(props: DownloadsProps) {
  const { items, title } = props
  const attachments = items?.[0]?.fooman_product_attachments ?? []

  if (attachments.length < 1) return null

  return (
    <ButtonLinkList title={title} containsBigLinks>
      {attachments.filter(nonNullable).map((fpa) => (
        <ListItemButton
          key={fpa.download_url}
          component={Button}
          onClick={() => {
            if (typeof window !== 'undefined' && fpa.download_url) {
              window.open(fpa.download_url, '_blank')
            }
          }}
          sx={(theme) => ({
            py: theme.spacings.xxs,
            px: 0,
            borderBottom: `1px solid ${theme.palette.divider}`,
          })}
        >
          <ListItemText>{fpa.file_name}</ListItemText>
          <ListItemIcon>
            <IconSvg src={iconDownload} size='medium' />
          </ListItemIcon>
        </ListItemButton>
      ))}
    </ButtonLinkList>
  )
}
