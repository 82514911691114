import { Container } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { RowLinksFragment } from '../RowLinks.gql'
import { Badge } from './Badge'

type BadgesProps = RowLinksFragment

export function Badges(props: BadgesProps) {
  const theme = useTheme()
  const { pageLinks } = props

  return (
    <Container
      maxWidth='lg'
      sx={{
        my: theme.spacings.lg,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: { xs: 'column', md: 'row' },
        gap: theme.spacings.sm,
        flexWrap: 'wrap',
      }}
    >
      {pageLinks.map((pageLink) => (
        <Badge key={pageLink.id} theme={theme} {...pageLink} />
      ))}
    </Container>
  )
}
