import { RichText } from '@graphcommerce/graphcms-ui'
import { HeroBanner } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Button, Typography } from '@mui/material'
import { RowHeroBannerFragment } from './RowHeroBanner.gql'

export function RowHeroBanner(props: RowHeroBannerFragment) {
  const { copy, heroAsset, pageLinks } = props

  if (!heroAsset)
    return (
      <Typography align='center'>
        <Trans id='Missing video [MP4]' />
      </Typography>
    )

  return (
    <>
      {heroAsset.mimeType !== 'video/mp4' && (
        <Typography align='center'>
          <Trans id='Video required [MP4]' />
        </Typography>
      )}
      <HeroBanner
        pageLinks={pageLinks.map(({ url, title }) => (
          <Button key={url} href={url} variant='outlined' size='large' color='inherit'>
            {title}
          </Button>
        ))}
        videoSrc={heroAsset.url}
        sx={(theme) => ({
          '& .HeroBanner-copy': {
            minHeight: { xs: 'min(70vh,600px)', md: 'min(70vh,1080px)' },
            [theme.breakpoints.up('sm')]: {
              padding: theme.spacings.xl,
              justifyItems: 'start',
              alignContent: 'center',
              textAlign: 'left',
              width: '50%',
            },
          },
        })}
      >
        <RichText
          {...copy}
          sxRenderer={{
            paragraph: {
              typography: 'overline',
            },
            'heading-one': (theme) => ({
              textTransform: 'uppercase',
              mt: 1,
              mb: theme.spacings.sm,
              '& strong': {
                WebkitTextFillColor: 'transparent',
                WebkitTextStroke: `1.2px #fff`,
              },
            }),
          }}
        />
      </HeroBanner>
    </>
  )
}
