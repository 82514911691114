import { RenderType, responsiveVal, breakpointVal } from '@graphcommerce/next-ui'
import { SxProps, Theme, Typography } from '@mui/material'
import { productListRenderer } from '../../../ProductListItems/productListRenderer'
import { FullWidthSlider } from '../FullWidthSlider'
import { RowProductFragment } from '../RowProduct.gql'
import { CrosssellProductsFragment } from './ProductCrosssells/CrosssellProducts.gql'

type CrosssellsProps = RowProductFragment & CrosssellProductsFragment & { sx?: SxProps<Theme> }

export function Crosssells(props: CrosssellsProps) {
  const { title, crosssell_products } = props

  if (!crosssell_products || crosssell_products.length === 0) return null

  return (
    <FullWidthSlider
      sx={(theme) => ({ marginBottom: theme.spacings.md })}
      title={
        <Typography
          component='h2'
          variant='h3'
          sx={(theme) => ({
            ...breakpointVal('fontSize', 18, 30, theme.breakpoints.values),
          })}
        >
          {title}
        </Typography>
      }
    >
      {crosssell_products?.map((item) =>
        item ? (
          <RenderType
            key={item.uid ?? ''}
            renderer={productListRenderer}
            sizes={responsiveVal(200, 400)}
            titleComponent='h3'
            {...item}
            sx={{ '& img': { mixBlendMode: 'multiply' } }}
          />
        ) : null,
      )}
    </FullWidthSlider>
  )
}
